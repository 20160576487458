









import { Vue, Component, Watch } from 'vue-property-decorator';
import { DbConnectionData } from '@/models/source';

@Component
export default class DbConnectionForm extends Vue {
  dbConnectionData: DbConnectionData = {
    host: '',
    port: -1,
    name: '',
    user: '',
    pass: '',
  }

  @Watch('dbConnectionData', { deep: true })
  onDbConnectionDataChange(dbConnectionData: DbConnectionData): void {
    this.$emit('dataChange', dbConnectionData);
  }
}
